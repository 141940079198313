import { DevTrace, Trace } from '@ctrip/corp-cross-utils'
import { IReq } from '@ctrip/corp-cross-utils/dist/constants'


interface IParams {
    isTrace?: boolean
    autoConsole?: boolean
    req?: IReq
}
export function log(key: string, value:any, params?: IParams) {
    const { isTrace, autoConsole = true } = params || {}
    const idDev = process.env.NODE_ENV !== 'production'
    idDev && autoConsole && console.log(key, value)
    return isTrace ? Trace(key, value) : DevTrace(key, value)
}